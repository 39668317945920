import { customFunctions } from "@/config/customFunction";
import { useModal } from "@/features/modal/ModalStackManager";
import { FileImportForm } from "@/features/ui/form/file";
import { BASE_URL } from "@/instance/axios";
import { Flex, Menu, Text } from "@mantine/core";
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react";
import axios from "axios";
import { Link as RouterLink } from "react-router-dom";

type LinkType = {
    label: string;
    links?: (Link | NestedLink)[];
    link?: string;
}[];

type Link = {
    link: string;
    label: string;
    action?: boolean;
    actionKey?: 'download' | 'upload';
};

type NestedLink = {
    label: string;
    links: (Link | NestedLink)[];
    action?: boolean;
    actionKey?: 'download' | 'upload';
};

const links: LinkType = [
    { link: "/dashboard", label: "대시보드" },
    {
        label: '시스템관리',
        links: [
            { link: '/system', label: 'system Access Log' },
            { link: '/system-logs', label: '로그정보 API 연동' },
            { link: '/system-parameter', label: '시스템파라미터 관리' },
        ]
    },
    {
        label: "기준정보",
        links: [
            { link: "/common", label: "공통코드" },
            { link: "/authority", label: "권한그룹" },
            { link: "/department", label: "부서코드" },
            { link: "/items", label: "품목" },
            { link: "/equipment", label: "설비" },
            { link: "/defects", label: "불량" },
            { link: "/operations", label: "공정" },
            { link: "/routing", label: "라우팅" },
            { link: "/users", label: "사용자" },
            { link: "/site", label: "사업장" },
            { link: "/location", label: "로케이션" },
            { link: "/routing-outsource", label: "외주처" },
            { link: "/ALCmodel", label: "ALC 모델코드" },
            { link: "/mold", label: "금형" },
            { link: "/moldStock", label: "금형재고" },
            { link: "/abstract-item", label: "품목군" },
            { link: "/item-category", label: "품목분류" },
            { link: "/partner", label: "협력사" },
            { link: "/downtimeReasons", label: "비가동사유(일시정지)" },
            { link: "/bom", label: "BOM등록(배합률)" },
            { link: "/bomList", label: "BOM전개조회" },
            { link: "/file", label: "문서관리(파일첨부)" },
            { link: "/#", label: "기준정보 다운로드", action: true, actionKey: 'download' },
            { link: "/#", label: "기준정보 업로드", action: true, actionKey: 'upload' },
        ],
    },
    {
        label: "생산관리",
        links: [
            { link: "/work-by-equipment", label: "설비별 생산현황" },
            { link: "/work-by-production-plan", label: "생산계획별 생산현황" },
            { link: "/work-by-row", label: "작업지시" },
            { link: "/work-log", label: "작업일지" },
            { link: "/work-complete", label: "작업완료" },
            { link: "/downtimeReasons-log", label: "정지유형별 통계" },
        ],
    },
    {
        label: "재고관리",
        links: [
            { link: "/inventory", label: "재고현황" },
            { link: "/ledgersAll", label: "전체 입출고 내역" },
            { link: "/wipInventory", label: "재공품 재고" },
            { link: "wmsLogs", label: "WMS 로그" },
            { link: "/receipt", label: '재고수불이력' },
            { link: "/barcodeLift", label: "재고바코드해제" },
            { link: "/barcodeMerge", label: "재고바코드병합" },
            { link: "/lackSafety", label: "안전재고부족조회" },
            { link: "/inventoryLife", label: "재고수명조회" },
        ],
    },
    {
        label: "입출고관리",
        links: [
            { link: "/shipment-request", label: "입고의뢰" },
            { link: "/receipt", label: "입고등록" },
            { link: "/shipment-status", label: "입고현황" },
            { link: "/shipment-return", label: "입고반품등록" },
        ]
    },
    {
        label: "영업관리",
        links: [
            { link: "/purchaseOrder", label: "수주현황" },
            { link: "/purchaseOrderItems", label: "수주항목" },
            { link: "/deliveryStatus", label: "납품현황" },
            { link: "/order", label: "발주현황" },
            { link: "/orderItems", label: "발주항목" },
            { link: "/stockPlan", label: "재고계획" },
            { link: "/transportation", label: "배차" },
        ].filter(link => {
            if (!customFunctions.ADD_ORDER_MENU_SETTING &&
                (link.link === "/purchaseOrderItems" ||
                    link.link === "/order" ||
                    link.link === "/orderItems" ||
                    link.link === "/stockPlan" ||
                    link.link === "/transportation")) {
                return false;
            }
            return true;
        })
    },
    {
        label: "품질관리",
        links: [
            { link: "/inspectSpecification", label: "검사기준 (초종중)" },
            { link: "/inspectionRecords", label: "검사기록" },
            { link: "/work-standard", label: "작업표준서 관리" },
            { link: "/productionInspection", label: "완제품검사" },
            { link: "/quality/defect-statistics", label: "불량통계(자주검사)" },
            // { link: "/quality/work-standardList", label: "작업표준서 관리" },
            // { link: "/quality/change-register", label: "변경점 정보 등록" },
            { link: "/quality/change-list", label: "변경점 정보 관리" },
            { link: "/quality/pre-warehouseList", label: "수입검사" },
        ].filter(link => {
            if (!customFunctions.ADD_QUALITY_MENU_SETTING &&
                (link.link === "/quality/defect-statistics" ||
                    link.link === "/quality/change-list" ||
                    link.link === "/quality/pre-warehouseList")) {
                return false;
            }
            return true;
        }),
    },
    {
        label: "KPI",
        links: [
            { link: "/kpi/leadtime", label: "제조리드타임(P)" },
            { link: "/kpi/manhour", label: "작업공수(C)" },
            { link: "/kpi/defect", label: "품질 불량률(O)" },
            { link: "/kpi/leadtimeOrder", label: "수주/납품 리드타임(D)" },
            { link: "/kpi/powerUsage", label: "전력사용량(E)" },
            { link: "/kpi/uph", label: "UPH(P)" },
            { link: "/kpi/rateOfOperation", label: "설비가동률(P)" },
        ]
    },
    {
        label: "설비금형기준",
        links: [
            { link: "/peripheral", label: "주변장치" },
            { link: "/tool", label: "공구" },
            { link: "/overview", label: "MES 운영현황판" },
            { link: "/monitoring", label: "현황 모니터링" },
            { link: '/pressMonitoring/factoryMonitoring', label: 'PMS 분석 모니터링' },
        ].filter(link => {
            if (!customFunctions.ADD_PMS_PRESS_MONITORING &&
                (link.link === "/pressMonitoring/factoryMonitoring")) {
                return false;
            }
            return true;
        }),
    },
    {
        label: "PMS",
        links: [
            {
                link: "/analysis", label: "프레스 통계 및 분석", links: [
                    { link: '/analysis/output', label: '생산량' },
                    { link: '/analysis/ability', label: '능력' },
                    { link: '/analysis/totaloutput', label: '총생산량' },
                    { link: '/analysis/error', label: '에러' },
                    { link: '/analysis/power', label: '전력' },
                    { link: '/analysis/idleTime', label: '기계 비 가동시간' },
                    { link: '/analysis/workTime', label: '작업시간' },
                ]
            },
            {
                link: "/press", label: "프레스 관리", links: [
                    { link: '/press/errorview', label: '에러보기' },
                    { link: '/press/parameterview', label: '파라미터 보기' },
                    { link: '/press/camview', label: '캠 보기' },
                    { link: '/press/clutchandbrake', label: '클러치 & 브레이크' },
                    { link: '/press/facilities', label: '설비 수리 요청' },
                    { link: '/press/complete/admin', label: '설비 수리 요청 완료(관리자)' },
                    { link: '/press/problem', label: '설비 문제 유형 등록' },
                    { link: '/press/PressDailyRegister', label: '프레스 일상점검 등록' },
                    { link: '/press/PressDailyStatus', label: '프레스 일상점검 일일현황' },
                ]
            },
            {
                link: '/moldManagement', label: '금형 관리', links: [
                    { link: '/mold/maintenance', label: '금형 타수 관리' },
                    { link: '/mold/repairs', label: '금형 수리 요청 등록' },
                    { link: '/mold/complete/admin', label: '금형 수리 완료 리스트(관리자)' },
                    { link: '/mold/problem', label: '금형 문제 유형 등록' },
                    { link: '/mold/MoldDailyRegister', label: '금형 일상점검 등록' },
                    { link: '/mold/MoldDailyStatus', label: '금형 일상점검 일일현황' },
                ]
            }
        ]
    },
    {
        label: "설비모니터링",
        links: [
            {
                label: "설비모니터링", links: [
                    { link: "/overview", label: "MES 운영현황판" },
                    { link: "/monitoring", label: "현황 모니터링" },
                    { link: "/analysisMonitoring", label: "분석 모니터링" },
                ]
            },
            {
                label: '설비관리', links: [
                    { link: "/errorView", label: "에러보기" },
                    { link: "/maintenance", label: "설비 수리 요청" },
                    { link: "/maintenanceList", label: "설비 수리 요청 LIST" },
                    { link: "/maintenanceComplete", label: "설비 수리 완료 LIST" },
                    { link: "/equipmentProblem", label: "설비 문제 유형 등록" },
                    { link: "/dailyRegister", label: "설비 일상점검 등록" },
                    { link: "/dailyList", label: "설비 일상점검 현황" },
                ]
            },
            {
                label: "설비 통계분석", links: [
                    { link: "/output", label: "생산량" },
                    { link: "/error", label: "에러" },
                    { link: "/power", label: "전력" },
                    { link: "/idleTime", label: "기계 비가동 시간" },
                    { link: "/workTime", label: "작업시간" },
                ]
            }
        ]
    },
    {
        label: "SCM",
        links: [
            {
                label: "영업관리(고객사)", links: [
                    { link: "/order", label: "발주등록 (고객사)" },
                    { link: "/purchaseOrderItems-gyeongnam", label: "수주현황 (경남)" },
                    { link: "/deliveryStatus-gyeongnam", label: "납품정보등록 (경남)" },
                    { link: "/deliveryList-company", label: "납품현황 (고객사)" },
                    { link: "/deliveryList-gyeongnam", label: "납품현황 (경남)" },
                ]
            },
            {
                label: "영업관리(협력사)", links: [
                    { link: "/order-gyeongnam", label: "발주등록 (경남)" },
                    { link: "/purchaseOrderItems-supplier", label: "수주현황 (협력사)" },
                    { link: "/deliveryStatus-supplier", label: "납품정보등록 (협력사)" },
                    { link: "/deliveryList-gyeongnam", label: "납품현황 (경남)" },
                    { link: "/deliveryList-supplier", label: "납품현황 (협력사)" },
                ]
            },
            {
                label: "입고관리(협력사)", links: [
                    { link: "/shipment-request_supplier", label: "입고의뢰" },
                    { link: "/receipt_supplier", label: "입고등록" },
                    { link: "/shipment-status_supplier", label: "입고현황" },
                    { link: "/label_supplier", label: "라벨발행" },
                ]
            }
        ],

    }
];

const RenderSubMenuItems = (items: (Link | NestedLink)[]) => {

    const { openModal } = useModal();

    const handleDownLoad = () => {
        const today = new Date();
        const formattedDate = today.toLocaleDateString('ko-KR');
        // 파일 다운로드 API
        axios
            .get(`${BASE_URL}/interport/export`, {
                responseType: "blob",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("authToken"),
                },
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `기준정보_${formattedDate}.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
    }

    const handleUpload = () => {
        openModal(<FileImportForm />, null, "기준정보 업로드");
    }

    return items.map((item) => {
        if ('links' in item) {
            return (
                <Menu.Item component="div" key={item.label}>
                    <Flex justify="space-between" align="center">
                        <Menu shadow="md" trigger="hover" transitionProps={{ exitDuration: 0 }} position="right" withArrow>
                            <Menu.Target>
                                <Flex justify="space-between" align="center" style={{ cursor: 'pointer', width: '100%' }}>
                                    <Text>{item.label}</Text>
                                    <IconChevronRight size="0.9rem" stroke={1.5} />
                                </Flex>
                            </Menu.Target>
                            <Menu.Dropdown>
                                {RenderSubMenuItems(item.links)}
                            </Menu.Dropdown>
                        </Menu>
                    </Flex>
                </Menu.Item>
            );
        }
        return (
            <Menu.Item
                key={item.label}
                component={RouterLink}
                to={item.action ? "#" : item.link}
                onClick={item.action ? (item.actionKey === 'download' ? handleDownLoad : item.actionKey === 'upload' ? handleUpload : undefined) : undefined}
                color={item.action ? 'indigo' : ''}
            >
                {item.label}
            </Menu.Item>
        );
    });
};

const renderMainMenuItems = (items: LinkType) => {
    return items.map((item) => (
        <Menu key={item.label} shadow="md" trigger="hover" transitionProps={{ exitDuration: 0 }} withArrow>
            <Menu.Target>
                {item.link ? (
                    <RouterLink to={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Flex align="center" style={{ cursor: 'pointer' }}>
                            <Text>{item.label}</Text>
                        </Flex>
                    </RouterLink>
                ) : (
                    <Flex align="center" style={{ cursor: 'pointer' }}>
                        <Text>{item.label}</Text>
                        {item.links && <IconChevronDown size="0.9rem" stroke={1.5} />}
                    </Flex>
                )}
            </Menu.Target>
            {item.links && (
                <Menu.Dropdown>
                    {RenderSubMenuItems(item.links)}
                </Menu.Dropdown>
            )}
        </Menu>
    ));
};

export const MenuTemplate = () => {
    const filteredLinks = links.filter(link => {
        const conditions = [
            { label: 'KPI', setting: customFunctions.ADD_KPI_MENU_SETTING },
            { label: 'PMS', setting: customFunctions.ADD_PMS_MENU_SETTING }
        ];

        return !conditions.some(condition => link.label === condition.label && !condition.setting);
    });

    return (
        <Flex gap="md">
            {renderMainMenuItems(filteredLinks)}
        </Flex>
    );
};
