import { useModal } from "@/features/modal/ModalStackManager";
import { ProductionPlanWithWorksForm } from "@/features/productionPlan/form/CreateForm";
import { DeliveryForm } from "@/features/purchaseOrder/form/deliveryForm";
import { ArrivalDeliveryStockPlanForm } from "@/features/purchaseOrderItems/form/arrivalDeliveryStockPlanForm";
import { PurchaseOrderItemsCreateForm } from "@/features/purchaseOrderItems/form/createForm";
import { PurchaseOrderItemsTable } from "@/features/purchaseOrderItems/table";
import { ActionButtonBox } from "@/features/standard/ActionButtonBox";
import { ActionHeader } from "@/features/standard/ActionHeader";
import { Header } from "@/features/standard/Header";
import { Main } from "@/features/standard/Main";
import { HeaderSubTitle } from "@/features/standard/SubTitle";
import { HeaderTitle } from "@/features/standard/Title";
import { Wrapper } from "@/features/standard/Wrapper";
import { usePub } from "@/hooks/usePubSub";
import { Button } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner } from "@sizlcorp/sizl-api-document/dist/models";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { useState } from "react";

const PurchaseOrder = Object.assign({}, Main, {
    Header: Header,
    HeaderTitle: HeaderTitle,
    HeaderSubTitle: HeaderSubTitle,
    Wrapper: Wrapper,
    ActionHeader: ActionHeader,
    ActionButton: Button,
    ActionButtonBox: ActionButtonBox,
    PurchaseOrderItemsTable: PurchaseOrderItemsTable,
    PurchaseOrderItemsCreateForm: PurchaseOrderItemsCreateForm,
    DeliveryForm: DeliveryForm,
    ProductionPlanWithWorksForm: ProductionPlanWithWorksForm
})

export const PurchaseOrderItemsPage = () => {
    const publish = usePub();
    const { openModal } = useModal();

    const [isRowBtnActive, setIsRowBtnActive] = useState<boolean>(false);
    const [selectedRowsInform, setSelectedRowsInform] = useState<ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner[]>([]);
    const [data, setData] = useState<any>([]);

    const handleRowSelect = (
        selectedRows: any,
        formatterPropsData: any,
    ) => {
        setIsRowBtnActive(selectedRows.size === 1);
        setData(formatterPropsData);
        setSelectedRowsInform(selectedRowsInform);
    }

    return (
        <>
            <PurchaseOrder.Wrapper>
                <PurchaseOrder.ActionHeader>
                    <PurchaseOrder.ActionButtonBox>
                        <PurchaseOrder.ActionButton
                            leftIcon={<IconPlus />}
                            onClick={() => openModal(
                                <PurchaseOrder.PurchaseOrderItemsCreateForm />,
                                null,
                                "수주 항목 추가",
                            )}
                        >
                            추가
                        </PurchaseOrder.ActionButton>
                        <PurchaseOrder.ActionButton
                            leftIcon={<IconPlus />}
                            color="orange"
                            disabled={!isRowBtnActive}
                            onClick={() => openModal(
                                <PurchaseOrder.DeliveryForm formatterProps={data} />,
                                null,
                                "납품하기",
                            )}
                        >
                            납품하기
                        </PurchaseOrder.ActionButton>
                        <PurchaseOrder.ActionButton
                            leftIcon={<IconPlus />}
                            color="green"
                            disabled={!isRowBtnActive}
                            onClick={() => openModal(
                                <ArrivalDeliveryStockPlanForm formatterProps={data} purchaseOrder={true} />,
                                null,
                                "출고계획",
                                true
                            )}
                        >
                            출고계획 작성
                        </PurchaseOrder.ActionButton>
                        <PurchaseOrder.ActionButton
                            leftIcon={<IconPlus />}
                            color="orange"
                            disabled={!isRowBtnActive}
                            onClick={() => openModal(
                                <ProductionPlanWithWorksForm formatterProps={data} />,
                                null,
                                "생산계획 작성",
                                true
                            )}
                        >
                            생산계획 작성
                        </PurchaseOrder.ActionButton>
                    </PurchaseOrder.ActionButtonBox>
                    <PurchaseOrder.ActionButton
                        color="red"
                        rightIcon={<IconTrash />}
                        onClick={() => publish("deleteSelectedItems")}
                    >
                        선택된 행 삭제
                    </PurchaseOrder.ActionButton>
                </PurchaseOrder.ActionHeader>
                <PurchaseOrder.Header>
                    <PurchaseOrder.HeaderTitle>수주 항목</PurchaseOrder.HeaderTitle>
                    <PurchaseOrder.HeaderSubTitle>
                        품목별 고객 주문건
                    </PurchaseOrder.HeaderSubTitle>
                </PurchaseOrder.Header>
                <PurchaseOrder.PurchaseOrderItemsTable onRowSelect={handleRowSelect} />
            </PurchaseOrder.Wrapper>
        </>
    );
}