import { LocationUpdateForm } from "@/features/location/form/UpdateForm";
import { CustomFilter } from "@/features/ui/Base/List/CustomFilter/CustomFilter";
import { SearchBox, SearchProps } from "@/features/ui/Base/List/SearchBox/SearchBox";
import { theme } from "@/styles/theme";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Button, Flex, Pagination, useMantineColorScheme } from "@mantine/core";
import { LocationsGet200ResponseRowsInner, MasterApiLocationsLocationIdDeleteRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import type { Column, SortColumn } from "react-data-grid";
import DataGrid, { FormatterProps, SelectColumn } from "react-data-grid";


import { locations, mutateLocations } from "@/api/locations/useLocationsQuery";
import { locationsHeader } from "@/constants/columnHeader";
import { queryDefaultValues } from "@/constants/queryDefaultValues";
import { useModal } from "@/features/modal/ModalStackManager";
import { SiteDetailForm } from "@/features/site/components/form/SiteDetailForm";
import { CustomSorter } from "@/features/ui/Base/List/CustomSorter/CustomSorter";
import customAlert from "@/features/ui/alert/alert";
import { DetailLink } from "@/features/ui/detail/DetailLink";
import { useSub } from "@/hooks";
import useQueryString from "@/hooks/useQueryString";
import useUpdateUrlParams from "@/hooks/useUpdateUrlParams";
import { getPrinterCodeByUserWithWhoami } from "@/utils/checkData";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { LocationDetailForm } from "../detail/LocationDetailForm";

interface Row {
  id?: number;
  siteCode: string;
  name: string;
  code: string;
}

interface ColorThemeProps {
  isDarkMode: boolean;
}

export const LocationTable = () => {
  const [initialQueryString, setQueryString] = useQueryString(queryDefaultValues);

  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const isDarkMode: boolean = colorScheme === "light";

  const [formatterProps, setFormatterProps] = useState<
    FormatterProps<Row, unknown> | undefined
  >();
  const [selectedRows, setSelectedRows] = useState(
    (): ReadonlySet<any> => new Set()
  );

  const [activePage, setPage] = useState(initialQueryString.page);
  const [query, setQuery] = useState(JSON.parse(initialQueryString.query));
  const [sort, setSort] = useState(initialQueryString.sort);
  const [search, setSearch] = useState<SearchProps>({
    search: initialQueryString.search,
    searchFields: initialQueryString.searchFields,
    pageSize: initialQueryString.pageSize,
  });

  const { openModal } = useModal();

  const { data: location, refetch } = useQuery(locations.get({
    query: query,
    search: search.search,
    searchFields: search.searchFields.length
      ? search.searchFields
      : locationsHeader.map((location) => location.value),
    page: activePage,
    pageSize: Number(search.pageSize),
    sort: sort.length ? sort.join(",") : "-id"
  }));

  const updateUrlParams = useUpdateUrlParams();

  // 새로운 데이터가 로드될 때, 현재 페이지가 유효하지 않으면 1페이지로 설정
  useEffect(() => {
    if (location && activePage > (location?.data?.totalPages ?? 0)) {
      setPage(1);
      setQueryString((prev) => ({
        ...prev,
        page: 1,
      }));
    }
  }, [location, activePage, setQueryString]);

  // 페이지 Query String으로 공정 이동
  useEffect(() => {
    updateUrlParams({
      page: activePage,
      pageSize: search.pageSize,
      search: search.search,
      searchFields: search.searchFields,
      query: JSON.stringify(query),
      sort: sort
    }, queryDefaultValues);
  }, [activePage, search, query, sort, updateUrlParams]);

  const rows: readonly LocationsGet200ResponseRowsInner[] =
    location?.data.rows ?? [];
  const selectedRowsRef = useRef<ReadonlySet<any>>(new Set());

  const { mutate: deleteMutate } = useMutation(
    (params: MasterApiLocationsLocationIdDeleteRequest) =>
      mutateLocations.delete(params).mutationFn(params as MasterApiLocationsLocationIdDeleteRequest | any),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const columns: readonly Column<Row>[] = [
    {
      ...SelectColumn,
      width: 70,
      maxWidth: 500,
      resizable: true,
      headerCellClass: css`
        & > * {
          justify-content: flex-start;
          padding-left: 24px;
        }
      `,
      cellClass: css`
        .rdg-checkbox-label {
          padding-left: 24px;
        }
      `,
    },
    {
      key: "code",
      name: "로케이션코드",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const detailItemAction = () => {
          openModal(
            <LocationDetailForm locationCode={formatterProps?.row.code} />,
            null,
            "로케이션 상세"
          );
        };
        return (
          <DetailLink
            onClick={detailItemAction}
          >
            {formatterProps.row.code}
          </DetailLink>
        );
      },
    },
    {
      key: "name",
      name: "로케이션이름",
      sortable: true,
      resizable: true,
    },
    {
      key: "siteCode",
      name: "사업장코드",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <DetailLink
            onClick={() =>
              openModal(
                <SiteDetailForm siteCode={formatterProps.row.siteCode} />,
                null,
                ""
              )
            }
          >
            {formatterProps.row.siteCode}
          </DetailLink>
        )
      },
    },
    {
      key: "options",
      name: "액션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        const deleteItemAction = async (tid: number) => {
          if (window.confirm("정말로 삭제하시겠습니까?")) {
            deleteItem(tid);
          }
        };

        // delete item after confirm
        const deleteItem = async (deleteItemId: number) => {
          deleteMutate(
            {
              locationId: deleteItemId,
            },
            {
              onSuccess: (res) => {
                if (res.status === 200) {
                  customAlert(
                    "로케이션 삭제에 성공하였습니다.",
                    "삭제 성공",
                    "green"
                  );
                } else {
                  customAlert(
                    "로케이션 삭제에 실패하였습니다.",
                    "삭제 실패",
                    "red"
                  );
                }
              },
            }
          );
        };

        return (
          <OptionBox>
            <OptionBtn
              variant="subtle"
              leftIcon={<IconEdit />}
              color="yellow"
              onClick={() =>
                openModal(
                  <LocationUpdateForm formatterProps={formatterProps?.row} />,
                  null,
                  "로케이션 수정",
                  true
                )
              }
            ></OptionBtn>
            <OptionBtn
              variant="subtle"
              leftIcon={<IconTrash />}
              color="red"
              onClick={() => {
                deleteItemAction(formatterProps?.row?.id as any);
              }}
            ></OptionBtn>
          </OptionBox>
        );
      },
    },
  ];

  useSub("deleteSelectedItems", () => {
    const rows = Array.from(selectedRowsRef.current);
    if (!rows.length) return;
    const deleteRows: number[] = Array.from(rows[rows.length - 1].values()).map((row) => JSON.parse(row as string).id);
    if (window.confirm("선택한 행을 삭제하시겠습니까?")) {
      deleteRows.forEach((row: number) => {
        deleteMutate({ locationId: row });
      });
      selectedRowsRef.current = new Set();
    }
  });

  useSub("printLocationBarcode", async () => {
    const rows = Array.from(selectedRowsRef.current);
    if (!rows.length) return;
    const printRows: string[] = Array.from(rows[rows.length - 1].values()).map((row) => JSON.parse(row as string).code);
    if (window.confirm("선택한 행을 출력하시겠습니까?")) {

      await axios.post(`${process.env.REACT_APP_COMPANY_BARCODE_ADDRESS_LOCATION}`, {
        "location_code": printRows,
        "company_code": process.env.REACT_APP_COMPANY_CODE, // 환경변수
        "printer_code": await getPrinterCodeByUserWithWhoami(),
      });

    }
  });

  return (
    <LocationTableWrapper>
      <GridWrapper isDarkMode={isDarkMode}>
        <TableWrapper>
          <RetrieveWrapper>
            <Flex direction="row" gap="xs" justify="flex-start" align="flex-center">
              <CustomFilter filterType={locationsHeader} setQuery={setQuery} query={query} />
              <CustomSorter sorterType={locationsHeader} setSort={setSort} sort={sort} />
            </Flex>
            <SearchBox searchType={locationsHeader} setSearch={setSearch} search={search} />
          </RetrieveWrapper>
          <DataGrid
            columns={columns ?? []}
            rows={rows as any}
            rowHeight={40}
            rowKeyGetter={(row: Row) => (JSON.stringify({ id: row.id, code: row.code }))}
            sortColumns={sortColumns}
            selectedRows={selectedRows}
            onSelectedRowsChange={(e) => {
              setSelectedRows(e);
              const newSelectedRows = new Set(selectedRowsRef.current);
              newSelectedRows.add(e);
              selectedRowsRef.current = newSelectedRows;
            }}
          />
        </TableWrapper>
        <PaginationWrapper>
          <Pagination
            onChange={setPage}
            value={activePage}
            total={location?.data?.totalPages ?? 0}
            size="lg"
            radius="sm"
          />
        </PaginationWrapper>
      </GridWrapper>
    </LocationTableWrapper>
  );
};

const LocationTableWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const GridWrapper = styled.div<ColorThemeProps>`
  & *[role="grid"] {
    height: inherit;
    --rdg-background-color: ${(props) => (props.isDarkMode ? "white" : "none")};
    --rdg-header-background-color: ${(props) =>
    props.isDarkMode ? "white" : "none"};
    --rdg-color: ${(props) => (props.isDarkMode ? "black" : "white")};
    --rdg-row-hover-background-color: ${(props) =>
    props.isDarkMode ? "#f5f5f5" : theme?.colors?.gray?.[7]};
  }
  & *[role="columnheader"] {
    // color: #7d8fa9;
    font-size: 12px;
    font-family: Roboto;
    font-weight: 500;
    word-wrap: break-word;
    // border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  & *[aria-colindex="1"] {
  }

  & *[role="row"] {
    height: 100px;
  }

  & *[role="gridcell"] {
    display: flex;
    align-items: center;
    // border-left: none;
    // border-right: none;
    box-shadow: none;

    & > * {
      justify-content: flex-start;
    }
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
`;
const OptionBox = styled.fieldset`
  display: flex;
  gap: 10px;
  border: none;
`;
const OptionBtn = styled<any>(Button)`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RetrieveWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: space-between;
`;
